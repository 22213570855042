.cc-wrap {
  .cc-mod-custom > label + div {
    display: block;
  }
  .cc-mod-control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    overflow: visible;
    height: 107px;
    width: 100%;
    &.is-invalid {
      border: none;
    }
    > img {
      position: absolute;
      left: 10px;
      z-index: 9;
      top: 29px;
      display: none;
    }
    > label {
      margin: 0;

      &::after {
        display: none;
      }

      input {
        position: relative;
        border: 1px solid red;

        &.error-border {
          border-color: red;
        }

        width: 100%;
        padding: 5px 8px;
        height: 40px;
        font-size: 16px;
        font-weight: normal;

        &::placeholder {
          opacity: 0;
        }
      }

      &:nth-child(2) {
        display: block;
        width: 100%;
        margin: 4px 0 8px 0;
      }
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
        align-self: start;
        &::before {
          display: block;
          font-size: 11px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 3px;
          margin-top: 8px;
        }
      }
      &:nth-child(3) {
        width: calc(60% - 3px);
      }
      &:nth-child(4) {
        left: auto;
        right: 0;
        width: calc(40% - 10px);
        margin-left: 10px;
      }
      .error-msg {
        margin-top: 5px;
        font-size: 12px;
        color: red;
      }
    }
  }

  #card-number,
  #card-expiry,
  #cvc {
    margin-top: 4px;
  }

  #country {
    margin-top: 70px;
  }
}
