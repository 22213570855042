.Loader {
  text-align: center;

  &_spinner {
    position: relative;
    margin: auto;
    width: 200px;
    height: 64px;
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);

    div {
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      position: absolute;
      animation: spinner linear 1s infinite;
      background: #337ab7; //You can change color here//
      width: 6px;
      height: 12px;
      border-radius: 40%;
      transform-origin: 3px 27px;

      &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -0.916666666666667s;
      }

      &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -0.833333333333333s;
      }

      &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.75s;
      }

      &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.666666666666667s;
      }

      &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.583333333333333s;
      }

      &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.5s;
      }

      &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.416666666666667s;
      }

      &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.333333333333333s;
      }

      &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.25s;
      }

      &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.166666666666667s;
      }

      &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.083333333333333s;
      }

      &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
    }
  }
}

@keyframes spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}