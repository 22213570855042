.PreLander {
  width: 100%;
  background-color: rgba(255, 0, 255, 0.08);
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  text-align: center;

  &_sample {
    padding: 8px;
  }
}